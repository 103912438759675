import { computed, Injectable, Injector, Signal, signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { neoMatIconPrefix } from '@app/core/models/ui.model';
import { asyncScheduler } from 'rxjs';
import { NeoBaseService } from './base/neo-base.service';

@Injectable({
  providedIn: 'root',
})
export class UiService extends NeoBaseService {
  /* Usage Notes: <mat-icon svgIcon="neo_client"></mat-icon> // svgIcon="<prefix>+<iconName>"*/
  svgIcons: { [key: string]: string[] } = {
    calendar: [
      'expand',
      'filled_check_circle',
      'in_progress',
      'in_review',
      'schedule',
      'tools',
      'two_staff',
      'view_more',
    ],
    'calendar-v2': [
      'bold_pen',
      'calendar_green',
      'calendar_orange',
      'calendar_red',
      'card_view_compact',
      'card_view_expanded',
      'card_view_slim',
      'chat_round',
      'chat_round_grey',
      'clock_grey',
      'equipment_drill',
      'equipment_excavator',
      'full_time_accepted_avatar',
      'full_time_avatar',
      'full_time_rejected_avatar',
      'grey_cross',
      'grey_search',
      'info_outline',
      'lock',
      'lock_primary',
      'lock_red',
      'more_horizontal',
      'more_vertical',
      'part_time_accepted_avatar',
      'part_time_avatar',
      'part_time_rejected_avatar',
      'red_cross',
      'red_exclamatory',
      'red_exclamatory_hexagonal',
      'repeatable',
      'snackbar_error',
      'snackbar_info',
      'snackbar_success',
      'snackbar_warning',
      'sort',
      'sub_contractor_accepted_avatar',
      'sub_contractor_avatar',
      'sub_contractor_rejected_avatar',
      'trash_bin',
      'trash_bin_red',
      'unlock',
      'unlock_primary',
      'red_exclamatory_hexagonal',
      'filter_green',
      'swap_green',
      'add_green'
    ],
    common: [
      'in_progress',
      'in_review',
      'approved',
      'not_submitted',
      'pending_for_approval',
      'rejected',
      'magnifier',
    ],
    sidebar: [
      'client',
      'dashboard',
      'diary_entries',
      'equipment_calendar',
      'export',
      'import',
      'info',
      'logo',
      'plant_register',
      'projects',
      'project_calendar',
      'push_pin',
      'push_unpin',
      'reporting',
      'setting',
      'staff',
      'template',
      'text_logo',
    ],
  };

  private styleClassMap = signal<Map<string, string>>(new Map<string, string>([
    ['active', 'status-active'],
    ['inactive', 'status-inactive'],
    ['expired', 'status-expired'],
    ['non-compliant', 'status-non-compliant'],
    ['compliance-due-soon', 'status-compliance-due-soon'],
    ['service-soon', 'status-compliance-due-soon'],
    ['compliant', 'status-compliant'],
    ['fulltime', 'employment-type-full-time'],
    ['parttime', 'employment-type-part-time'],
    ['subcontractor', 'employment-type-sub-contractor'],
    ['all', 'filter-all'],
  ]));

  constructor(
    protected injector: Injector,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    super(injector);
  }

  static isNeoMatIcon(name: string = ''): boolean {
    return !!name?.startsWith(neoMatIconPrefix);
  }

  public registerSvgIcons(): void {
    const prefix = neoMatIconPrefix;
    for (const dirName of Object.keys(this.svgIcons)) {
      this.svgIcons[dirName].forEach((svgIcon) => {
        this.matIconRegistry.addSvgIcon(
          `${prefix}${svgIcon}`,
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            `../../assets/${dirName}/${svgIcon}.svg`
          )
        );
      });
    }
  }

  static scrollElementIntoView(selector: string, offset: string = '25vh', animationDuration: number = 2500): void {
    asyncScheduler.schedule(() => {
      const element: HTMLElement = document.querySelector(selector) as HTMLElement;
      if (!element) return;

      element.style.scrollMarginTop = offset;
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      element.style.scrollMarginTop = '';

      const allErroredFields = document.querySelectorAll(selector);
      allErroredFields.forEach(element => {
        element.classList.add('shake-error-animation');
      });

      asyncScheduler.schedule(() => {
        allErroredFields.forEach(element => element.classList.remove('shake-error-animation'));
      }, animationDuration);
    });
  }

  public getMappedStyleClass = (key: string): Signal<string> => computed(() => {
    const map: Map<string, string> = this.styleClassMap();
    return map.get(key.toLowerCase()) || '';
  });

}
